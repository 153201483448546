<template>
  <div class="addorder">
    <el-dialog :visible.sync="dialogVisible" width="80%" destroy-on-close>
      <!-- <div class="BBg" :style="{ height: isheight }" ></div> -->
      <div class="flex_between pb10 bbe9s">
        <div class="title f-left p10">商机池任务信息</div>
      </div>
      <div class="flex_warp">
        <!-- 客户姓名 -->
        <div class="blocks2 flex_items flex_start">
          <span class="mr20" style="width: 87px !important"
            >客户姓名<span style="color: red">*</span></span
          >
          <el-input v-model="Customer" placeholder="请输入客户姓名"></el-input>
        </div>
        <!-- 联系方式 -->
        <div class="blocks2 flex_items flex_start">
          <span class="mr20 liTitle2"
            >联系方式<span style="color: red">*</span></span
          >
          <el-input
            v-model="phone"
            placeholder="请输入联系方式"
            @blur="phone2"
          ></el-input>
        </div>
        <!-- 性别 -->
        <div class="blocks2 flex_items flex_start">
          <span class="mr20">性别</span>
          <el-radio v-model="sex" label="1">男</el-radio>
          <el-radio v-model="sex" label="2">女</el-radio>
        </div>
        <!-- 下单时间 -->
        <div class="blocks2 flex_items flex_start">
          <span class="mr20 liTitle"
            >下单时间<span style="color: red">*</span></span
          >
          <el-date-picker
            :clearable="true"
            v-model="xiadantime"
            type="datetime"
            placeholder="选择下单时间"
          ></el-date-picker>
        </div>
      </div>
      <!-- 客户地址 -->
      <div
        class="blocks2 flex_items flex_start addres"
        style="margin-bottom: -10px"
      >
        <span class="mr20 liTitle" style="margin-right: 16px">客户地址</span>
        <div class="flex_start">
          <el-select
            v-model="oneplace"
            placeholder="选择省"
            @change="choseplace(oneplace, 1)"
            class="mr10 mt10 quyu"
          >
            <!-- :key="item.id" -->
            <el-option
              v-for="(item, index) in place1"
              :key="index"
              :label="item.province"
              :value="item.province_id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="twoplace"
            placeholder="选择市"
            @change="choseplace(twoplace, 2)"
            class="mr10 mt10 quyu"
          >
            <!-- :key="item.id" -->
            <el-option
              v-for="(item, index) in place2"
              :key="index"
              :label="item.city"
              :value="item.city_id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="threeplace"
            placeholder="选择区/县"
            @change="choseplace(threeplace, 3)"
            class="mr10 mt10 quyu"
          >
            <!-- :key="item.id" -->
            <el-option
              v-for="(item, index) in place3"
              :key="index"
              :label="item.county"
              :value="item.county_id"
            >
            </el-option>
          </el-select>
          <!-- <el-input class="mt10 quyu" v-model="menpai" placeholder="请输入门牌号" style="width:117px"></el-input> -->
        </div>
      </div>
      <div style="color: red; margin-top: 20px">
        商机池地址：{{ addressssssss }}
      </div>
      <!-- 小区名称 -->
      <div
        class="blocks2 flex_items flex_start pos"
        style="margin-bottom: -10px"
      >
        <span class="mr20 liTitle liTitle" style="margin-right: 16px"
          >小区名称<span style="color: red">*</span></span
        >
        <el-input
          v-model="House"
          placeholder="请输入小区名称"
          style="margin-right: 10px; width: 220px"
          @input="getxiaoqu()"
        >
        </el-input>
        <!--    @blur.stop="defaultHouse()" -->
        <ul
          v-if="xiaoqukuang && NewItem3.length != 0"
          class="abs wuyekuang"
          style="width: 225px; left: 85px; top: 50px"
        >
          <li
            v-for="(item, index) in NewItem3"
            :key="index"
            :value="item.title2"
            v-text="item.title2"
            @click.stop="chosexiaoqu(item, index)"
          ></li>
        </ul>

        <div class="blocks2 flex_items flex_start mr20 mt10">
          <span class="mr20 titleBottom">楼栋:</span>
          <el-input
            class="mt10 quyu mr10 inputBottom"
            v-model="building"
            placeholder="请输入"
            style="width: 150px"
          >
          </el-input>
          <span class="mr20 titleBottom">单元:</span>
          <el-input
            class="mt10 quyu mr10 inputBottom"
            v-model="unit"
            placeholder="请输入"
            style="width: 150px"
          ></el-input>
          <span class="mr20 titleBottom">房号:</span>
          <el-input
            class="mt10 quyu mr10 inputBottom"
            v-model="room"
            placeholder="请输入"
            style="width: 150px"
          ></el-input>
        </div>
      </div>
      <span style="font-size: 14px; color: red; display: none"
        >可以搜索选择地址或者从地图选取地址</span
      >
      <div class="map" style="width: 90%; height: 310px; display: none">
        <div
          id="container"
          style="width: 100%; height: 300px; margin-top: 20px"
        ></div>
      </div>
      <div class="flex_warp">
        <!-- 是否会员 -->
        <div class="blocks2 flex_items flex_start">
          <span class="mr20">是否会员</span>
          <span class="mr20">{{ isVip == 2 ? "不是" : "是" }}</span>
        </div>
        <!-- 新老用户 -->
        <div class="blocks2 flex_items flex_start">
          <span class="mr20">新老用户</span>
          <span class="mr20">{{
            newOrOldUser == 2 ? "老用户" : "新用户"
          }}</span>
        </div>

        <div class="blocks2 flex_items flex_start">
          <span class="mr20">是否有过报价</span>
          <el-radio v-model="isQuote" :label="1">是</el-radio>
          <el-radio v-model="isQuote" :label="2">否</el-radio>
        </div>
      </div>

      <!-- 订单来源 -->
      <div class="blocks2 flex_items flex_start" style="margin-bottom: 20px">
        <span class="mr20 liTitle" style="margin-right: 16px"
          >订单来源<span style="color: red">*</span></span
        >
        <el-select
          v-model="onewhere"
          placeholder="一级来源"
          @change="chosewhere(onewhere)"
          class="mr10"
        >
          <el-option
            v-for="item in sources"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
        <!-- twowhere != '' -->
        <el-select
          v-if="sources2.length != 0"
          v-model="twowhere"
          placeholder="二级来源"
          @change="chosewhere1($event)"
        >
          <el-option
            v-for="item in sources2"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div style="width: 95%">
        <div style="margin: 8px 0 8px 0">
          问题标签<span style="color: red">*</span>
        </div>
        <div>
          <div style="display: flex">
            <div
              v-for="(item, index) in problems"
              :key="index"
              :style="
                index > 2
                  ? 'flex: 0 0 15%;border: 1px solid #c0c4cc;margin-right: 5px;margin-bottom: 10px;padding: 8px;'
                  : 'flex: 0 0 20%;border: 1px solid #c0c4cc;margin-right: 5px;margin-bottom: 10px;padding: 8px;'
              "
            >
              <span class="f-bold f-14">{{ item.title }}</span>
              <el-radio
                v-model="radio"
                :label="item.id"
                style="margin-left: 10px"
                @change="radioChange(radio)"
                >主问题
              </el-radio>
              <div style="margin: 8px 0 8px 0; font-size: 14px">位置</div>
              <el-checkbox-group
                v-model="labelCheck"
                @change="handleChange()"
                size="mini"
              >
                <el-checkbox-button
                  v-for="(item1, index1) in item.position"
                  :key="index1"
                  :label="item1.id"
                  >{{ item1.title }}</el-checkbox-button
                >
              </el-checkbox-group>
              <div style="margin: 8px 0 8px 0; font-size: 14px">问题类型</div>
              <el-checkbox-group
                v-model="labelCheck1"
                @change="handleChange1()"
                size="mini"
              >
                <el-checkbox-button
                  v-for="(item2, index2) in item.problem"
                  :key="index2"
                  :label="item2.id"
                  >{{ item2.title }}</el-checkbox-button
                >
              </el-checkbox-group>

              <!-- 个性化问题 -->
              <div v-for="(i, k) in item.personality" :key="k">
                <div
                  style="
                    margin: 8px 0 8px 0;
                    font-size: 14px;
                    font-weight: bold;
                  "
                >
                  {{ i.title }}
                </div>
                <div v-for="(j, r) in i.childern" :key="r">
                  <!-- 单选 -->
                  <div v-if="j.choice == 1">
                    <div style="margin: 8px 0 8px 0; font-size: 14px">
                      {{ j.title }}
                    </div>
                    <el-radio-group
                      v-model="j.choooooose"
                      @change="perRadioChange()"
                    >
                      <el-radio
                        :label="u.id"
                        v-for="(u, n) in j.childern"
                        :key="n"
                        >{{ u.title }}</el-radio
                      >
                    </el-radio-group>
                  </div>
                  <!-- 多选 -->
                  <div v-if="j.choice == 2">
                    <div style="margin: 8px 0 8px 0; font-size: 14px">
                      {{ j.title }}
                    </div>
                    <el-checkbox-group v-model="j.choooooose">
                      <el-checkbox
                        :label="u.title"
                        v-for="(u, n) in j.childern"
                        :key="n"
                        >{{ u.title }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <!-- 输入框 -->
                  <div v-if="j.choice == 3">
                    <div style="margin: 8px 0 8px 0; font-size: 14px">
                      {{ j.title }}
                    </div>
                    <el-input
                      type="number"
                      @input="inputChange($event)"
                      v-model="j.choooooose"
                      placeholder="请输入内容"
                    >
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 房屋问题 -->
      <div
        class="blocks2 flex_items flex_start"
        style="margin-bottom: 5px"
        v-if="radio"
      >
        <span class="mr20">房屋问题</span>
        <div class="flex_start" style="margin-left: 16px">
          <el-select
            v-model="onequestion"
            placeholder="一级问题"
            @change="choseques(onequestion, '1', 'hand')"
            class="mr10 mt10"
          >
            <el-option
              v-for="item in question1"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="twoquestion"
            placeholder="二级问题"
            @change="choseques(twoquestion, '2', 'hand', 'handtwo')"
            class="mr10 mt10"
          >
            <el-option
              v-for="item in question2"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="threequestion"
            placeholder="三级问题"
            @change="choseques(threequestion, '3', 'hand', 'handthree')"
            class="mr10 mt10"
          >
            <el-option
              v-for="item in question3"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>

      <!-- 用户打标签 -->
      <div style="border: 1px solid #ccc; width: 93%; padding: 10px">
        <div>用户打标</div>
        <el-form :model="form" label-width="100px">
          <div style="display: flex">
            <div style="display: flex; flex-wrap: wrap">
              <el-form-item
                v-for="(item, index) in arr"
                :key="index"
                :label="item.title"
                style="width: 33%"
              >
                <div style="display: flex">
                  <el-checkbox-group
                    v-model="form[item.title]"
                    size="mini"
                    v-if="item.data[0].option === 2"
                    style="flex: 10"
                  >
                    <el-checkbox-button
                      v-for="ra in item.data"
                      :label="ra"
                      :key="ra.contacts_id"
                      >{{ ra.content }}
                    </el-checkbox-button>
                  </el-checkbox-group>
                  <el-radio-group
                    v-model="form[item.title]"
                    size="mini"
                    style="flex: 10"
                    v-else
                  >
                    <el-radio-button
                      v-for="ra in item.data"
                      :label="ra"
                      :key="ra.contacts_id"
                      >{{ ra.content }}
                    </el-radio-button>
                  </el-radio-group>
                  <el-image
                    v-if="
                      form[item.title].length !== 0 && item.data[0].option === 2
                    "
                    src="https://imgaes.yiniaoweb.com/cc/finish.png"
                    style="
                      width: 20px;
                      height: 20px;
                      margin: 10px 0 0 2px;
                      flex: 1;
                    "
                    fit="scale-down"
                  ></el-image>
                  <el-image
                    v-if="
                      form[item.title].length === 0 && item.data[0].option === 2
                    "
                    src="https://imgaes.yiniaoweb.com/cc/nofinish.png"
                    style="
                      width: 20px;
                      height: 20px;
                      margin: 10px 0 0 2px;
                      flex: 1;
                    "
                    fit="scale-down"
                  ></el-image>
                  <el-image
                    v-if="form[item.title] !== '' && item.data[0].option === 1"
                    src="https://imgaes.yiniaoweb.com/cc/finish.png"
                    style="
                      width: 20px;
                      height: 20px;
                      margin: 10px 0 0 2px;
                      flex: 1;
                    "
                    fit="scale-down"
                  ></el-image>
                  <el-image
                    v-if="form[item.title] === '' && item.data[0].option === 1"
                    src="https://imgaes.yiniaoweb.com/cc/nofinish.png"
                    style="
                      width: 20px;
                      height: 20px;
                      margin: 10px 0 0 2px;
                      flex: 1;
                    "
                    fit="scale-down"
                  ></el-image>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <!-- 物业 -->
      <div
        class="blocks2 flex_items flex_warp"
        style="overflow: inherit !important"
      >
        <!-- 选择物业 -->
        <div
          class="flex_start flex_items pos mr20 mt10"
          style="margin-bottom: 10px"
        >
          <span class="mr34">选择物业</span>
          <el-select v-model="wuyeVal" placeholder="请选择物业" filterable>
            <el-option
              v-for="item in items"
              :key="item.group_id"
              :label="item.username"
              :value="item.username"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 推荐姓名 -->
        <div class="flex_items flex_start mr20 mt10">
          <span class="mr20 liTitle2">推荐姓名</span>
          <el-input v-model="Recommend" placeholder="请输入推荐姓名"></el-input>
        </div>
      </div>
      <div class="flex_items flex_start mr20 mt10">
        <span class="mr20 liTitle" style="margin-right: 20px">推客人员</span>
        <el-select
          class="mr10"
          filterable
          v-model="tuiren"
          collapse-tags
          placeholder="选择推客人员"
          :clearable="true"
          @change="moren"
        >
          <!-- @change="pro_id" -->
          <!-- :key="item.personal_id" -->
          <el-option
            v-for="(item, index) in tuirens"
            :key="index"
            :label="item.tuiShow"
            :value="item.personal_id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 指派店长 -->
      <div
        v-if="stytem == 0"
        class="flex_items flex_start mt20 pos"
        style="width: 100%"
      >
        <span class="mr20 liTitle"
          >指派店长<span style="color: red">*</span></span
        >
        <!-- @focus="shop()" -->
        <!-- @input="searchShopper()" -->
        <el-input
          v-model="shopper"
          placeholder="选择指派店长"
          @focus="getdian"
          style="width: 205px"
          :clearable="true"
        >
        </el-input>
        <div
          v-if="diankuang && NewItems2.length != 0"
          class="abs diankuang"
          style="width: 88%; left: 88px"
        >
          <el-table :data="NewItems2" border @row-click="chosedian">
            <el-table-column
              prop="usernamestorename"
              label="店长"
              min-width="150px"
            ></el-table-column>
            <el-table-column
              prop="work_tag"
              label="标签"
              min-width="150px"
            ></el-table-column>
            <el-table-column
              prop="turnovers"
              label="成交率(%)"
            ></el-table-column>
            <el-table-column prop="fraction" label="分数"></el-table-column>
            <el-table-column prop="distance" label="距离(km)"></el-table-column>
            <el-table-column prop="state" label="状态">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.state == 1" type="success">在线</el-tag>
                <el-tag v-else type="danger">离线</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderCount"
              label="今日已接单数"
            ></el-table-column>
            <el-table-column
              prop="punch_clock"
              label="今日上门"
              min-width="170px"
            >
              <template slot-scope="scope">
                <div
                  v-for="(item, index) in scope.row.punch_clock"
                  :key="index"
                >
                  {{ item }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="estimate" label="今日打卡" min-width="170px">
              <template slot-scope="scope">
                <div v-for="(item, index) in scope.row.estimate" :key="index">
                  {{ item }}
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="estimate" label="今日打卡"></el-table-column> -->
            <el-table-column label="当前位置" min-width="170px">
              <template slot-scope="scope">
                <el-button type="success" @click="viewLocation(scope.row)"
                  >查看位置</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <p
          class="zanwudian"
          v-if="NewItems2.length == 0 && nodiankuang"
          @click="chosezanwu"
        >
          --暂无数据--
        </p>
      </div>
      <!-- 备注说明 -->
      <div class="flex_start flex_items mt20">
        <span class="mr20 liTitle" style="margin-right: 16px">备注说明</span>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入备注"
          v-model="textarea"
        ></el-input>
      </div>
      <!-- 图片上传 -->
      <div class="blocks2 flex_items flex_start">
        <span class="mr20 liTitle" style="margin-right: 16px">问题图片</span>
        <el-upload
          drag
          action="https://public.yiniao.co/api/v1/upload"
          list-type="picture-card"
          :multiple="mutrue"
          :on-preview="handlePictureCardPreview"
          :on-success="upsuccess"
          :on-remove="afterRemove"
          :file-list="fileList"
          :data="tuzhi"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="tudiag" :modal="false">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
      </div>
      <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
        <span class="mr20" style="margin-right: 25px">发送短信</span>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="店长"></el-checkbox>
          <el-checkbox label="客户"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
        <span class="mr20">客服是否添加微信</span>
        <el-radio v-model="wechat" label="1">是</el-radio>
        <el-radio v-model="wechat" label="0">否</el-radio>
      </div>
      <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
        <span class="mr20">是否为精装房</span>
        <el-radio v-model="hardbound" :label="1">是</el-radio>
        <el-radio v-model="hardbound" :label="0">否</el-radio>
      </div>
      <!-- 是否系统自动派单 -->
      <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
        <span class="mr20">是否选择系统派单</span>
        <el-radio v-model="stytem" label="1">是</el-radio>
        <el-radio v-model="stytem" label="0">否</el-radio>
      </div>
      <!-- 派单类型 -->
      <!-- ----------------------------------------------------------------------dispatchType -->
      <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
        <span class="mr20">派单类型</span>
        <el-radio v-model="dispatchType" :label="1">直派用户</el-radio>
        <el-radio v-model="dispatchType" :label="2">咨询用户</el-radio>
      </div>
      <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
        <span class="mr20">期望上门时间</span>
        <el-radio v-model="visiting_time" :label="1">立即</el-radio>
        <el-radio v-model="visiting_time" :label="2">三天内</el-radio>
        <el-radio v-model="visiting_time" :label="3">七天内</el-radio>
        <el-radio v-model="visiting_time" :label="4">不着急</el-radio>
      </div>
      <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
        <span class="mr20">预计施工处理时间</span>
        <el-radio v-model="constructionTime" :label="1">三天内</el-radio>
        <el-radio v-model="constructionTime" :label="2">一周内</el-radio>
        <el-radio v-model="constructionTime" :label="3">一月内</el-radio>
        <el-radio v-model="constructionTime" :label="4">不着急</el-radio>
        <el-radio v-model="constructionTime" :label="5">指定时间</el-radio>
        <el-date-picker
          v-if="constructionTime == 5"
          :clearable="true"
          v-model="specifiedTime"
          type="datetime"
          placeholder="选择下单时间"
        ></el-date-picker>
      </div>
      <!-- 是否免订单费 -->
      <div class="blocks2 flex_items flex_start" style="margin-top: 20px">
        <span class="mr20">是否免订单费</span>
        <el-radio v-model="orderFee" :label="1">是</el-radio>
        <el-radio v-model="orderFee" :label="0">否</el-radio>
      </div>
      <!-- 选择无效订单原因 -->
      <el-cascader
        v-if="wuxiao == 1"
        :options="options"
        v-model="wuxiaozhi"
        class="mt20"
        placeholder="选择无效原因"
      >
      </el-cascader>
      <div class="blocks2 pb30 bbe9s">
        <el-button
          type="primary"
          plain
          style="margin-right: 30px; margin-top: 30px"
          @click="addsub('yes')"
          >指派订单
        </el-button>
        <el-button v-if="wuxiao == 0" @click="wuxiao2"
          >选择无效订单原因</el-button
        >

        <el-button v-else @click="addsub('no')">保存无效订单</el-button>
        <!-- <el-button @click="addsub('yes', true)">转至商机池</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>
<script
  charset="utf-8"
  src="https://map.qq.com/api/js?v=2.exp&key=QWCBZ-MG26P-QNNDD-VLXUT-VJSPT-NVBLG"
></script>
<script>
if (!Array.prototype.some) {
  Array.prototype.some = function (fun /*, thisp*/) {
    var len = this.length;
    if (typeof fun != "function") throw new TypeError();
    var thisp = arguments[1];
    for (var i = 0; i < len; i++) {
      if (i in this && fun.call(thisp, this[i], i, this)) return true;
    }
    return false;
  };
}

export default {
  props: ["currentPage3", "poolOrderInfo"],
  data() {
    return {
      addressssssss: "",
      pool: false, //订单池
      //新增字段
      sex: 1, //性别
      isVip: 0, //是否会员
      newOrOldUser: 0, //新老用户
      isQuote: 0, //是否有过报价
      perProblem: [], //个性化问题
      // currentPage3:0,
      orderFee: 0,
      visiting_time: 1, //期望上门时间
      dispatchType: 1, //派单类型
      constructionTime: "", //期望施工时间
      specifiedTime: "", //指定时间
      hardbound: 0,
      addressValue: "",
      building: "",
      unit: "",
      room: "",
      currentLat: "",
      currentLong: "",
      searchKey: "万通",
      currentAddress: "", //搜索的地点
      positionList: [],
      markersArray: [],
      geocoder: [],
      searchService: {},
      xiaoqu: "",
      street: "",
      latitude: 30.69126,
      longitude: 104.05114,
      markerLayer: {},
      map: {},
      labelCheck: [],
      labelCheck1: [],
      isheight: "",
      Customer: "",
      xiadantime: "",
      wuxiaozhi: "",
      phone: "",
      wuxiao: 0,
      lat: "",
      lng: "",
      details: {
        pro_id: "",
        pro_id1: "",
        pro_id2: "",
        province_id: "",
        city_id: "",
        county_id: "",
        state: "是",
        stat: "",
        stat1: "",
        stat2: "",
      },
      // 小区名称搜索
      Houseitems: [],
      xiaoqukuang: false,
      nowuye: false,
      //   获取地址
      place1: [],
      place2: [],
      place3: [],
      oneplace: "",
      twoplace: "",
      threeplace: "",
      detailedplace: "",
      House: "",
      diandianid: "",
      //   房屋问题
      question1: [],
      question2: [],
      question3: [],
      onequestion: "",
      twoquestion: "",
      threequestion: "",
      //   物业
      wuyeVal: "",
      wuyeid: "",
      wuyekuang: false,
      nowuye: false,
      items: [],
      //   推荐人
      Recommend: "",
      // 店长
      shoppers: [],
      shopper: "",
      shopperid: "",
      textarea: "",
      diankuang: false,
      nodiankuang: false,
      // 图片上传
      mutrue: true,
      dialogImageUrl: "",
      tudiag: false,
      tuzhi: { app_name: "public" },
      imgs: [],
      fileList: [],
      // 订单来源
      sources: [],
      sources2: [],
      onewhere: "",

      property: 0,
      twowhere: "",
      loading: true,
      menpai: "",
      // 是否发送短信
      checkList: ["店长", "客户"],
      // 是否家微信
      wechat: "0",
      // 系统派单
      stytem: "0",
      // 无效订单原因
      options: [
        {
          value: "超服务范围",
          label: "超服务范围",
          children: [
            {
              value: "距离太远",
              label: "距离太远",
            },
            {
              value: "其他",
              label: "其他",
            },
          ],
        },
        {
          value: "超业务范围",
          label: "超业务范围",
          children: [
            {
              value: "做不了",
              label: "做不了",
            },
            {
              value: "客户不做了",
              label: "客户不做了",
            },
            {
              value: "无人接单",
              label: "无人接单",
            },
            {
              value: "联系不上",
              label: "联系不上",
            },
            {
              value: "价格原因",
              label: "价格原因",
            },
            {
              value: "时间原因",
              label: "时间原因",
            },
            {
              value: "广告推销",
              label: "广告推销",
            },
            {
              value: "点错了",
              label: "点错了",
            },
            {
              value: "求职合作",
              label: "求职合作",
            },
            {
              value: "业务调整",
              label: "业务调整",
            },
            {
              value: "责任归属",
              label: "责任归属",
            },
            {
              value: "其他",
              label: "其他",
            },
          ],
        },
        {
          value: "未及时接待",
          label: "未及时接待",
          children: [
            {
              value: "客服原因",
              label: "客服原因",
            },
            {
              value: "系统原因",
              label: "系统原因",
            },
            {
              value: "时间段原因",
              label: "时间段原因",
            },
            {
              value: "其他",
              label: "其他",
            },
          ],
        },
        {
          value: "未接待下来",
          label: "未接待下来",
          children: [
            {
              value: "话术或专业不足",
              label: "话术或专业不足",
            },
            {
              value: "一句话无回应",
              label: "一句话无回应",
            },
            {
              value: "要去门店",
              label: "要去门店",
            },
            {
              value: "询价",
              label: "询价",
            },
            {
              value: "纯咨询",
              label: "纯咨询",
            },
            {
              value: "其他",
              label: "其他",
            },
          ],
        },
      ],
      tuiren: "",
      tuirens: [],
      problems: [],
      problemArr: [],
      radio: "",
      location: {
        lat: "",
        lng: "",
      },
      notifyInstance: "",
      form: {
        // 性别: "",
        // 年龄段: "",
        // 房屋关系: "",
        // 婚否: "",
        // 孩否: "",
        // 孩年龄段: "",
        // 父母同住: "",
        // 父母年龄段: "",
        // 宠物: "",
        // 职业: "",
        // 兴趣爱好: [],
        // 当前装修水平: "",
        // 居住环境: ""
      },
      form: {},
      arr: [],
      // NewItems2:[]
      dialogVisible: false,
      isOrderPool: "",
    };
  },
  mounted() {
    if (this.dialogVisible) {
    }
  },
  created() {
    this.Customer = this.$store.state.form.contacts;
    this.phone = this.$store.state.form.telephone;
    this.sex = this.$store.state.form.sex;
    this.oneplace = this.$store.state.form.province_id;
    this.twoplace = this.$store.state.form.city_id;
    this.threeplace = this.$store.state.form.county_id;
    this.isOrderPool = this.$store.state.form.order_pool_id;
    console.log(
      "this.$store.state.form.province_id;",
      this.$store.state.form.province_id
    );

    // this.dialogVisible = true;
  },
  methods: {
    // 查看店长位置
    viewLocation(row) {
      console.log("当前row", row);
      let userId = row.user_id;
      let routeData = this.$router.resolve({
        query: { userId: userId },
        path: "/mapLocation",
      });
      window.open(routeData.href, "_blank");
    },
    //====================================================================>
    handleClose() {
      this.dialogVisible = false;
      this.$store.state.poolToAsign = false;

      //   this.$parent.cloosePoolToAsign();
    },
    handleShow(e) {
      this.geetInfo(e);
    },

    geetInfo(e) {
      this.util
        .get(this.HOST + "/OrderPool/info", {
          orderPoolId: e,
        })
        .then((res) => {
          if (res.code == 200) {
            console.log("=====================>", res.data.sex);
            this.Customer = res.data.contacts;
            this.phone = res.data.telephone;
            this.sex = res.data.sex == "男" ? "1" : "2";
            this.isOrderPool = res.data.order_pool_id;
            this.addressssssss = res.data.addres;
            this.textarea = res.data.remarks;
            this.details.province_id = res.data.province_id;
            this.details.city_id = res.data.city_id;
            this.details.county_id = res.data.county_id;
            this.oneplace = res.data.province_id;
            this.twoplace = res.data.city_id;
            this.threeplace = res.data.county_id;
            this.diandianid = res.data.city_id;

            this.onewhere = res.data.channel_id;
            this.twowhere = res.data.channel_details;
            this.radio = res.data.pro_id;
            this.getquestion(0, "1");
            this.getNewProvince(0, "1");
            // this.getwhere();
            // this.getChanelList();
            this.getContactLabel();
            this.shop();
            // this.chosewhere(res.data.channel_id);
            this.dialogVisible = true;

            // this.isOrderPool = ""; //1：转至商机池2：不移订单池
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },

    getNewProvince(id, times, c) {
      console.log("id", id, this.twoplace);

      this.util
        .get(this.HOST + "/Common/newProvince", {
          city_id: id,
          structures: times,
        })
        .then((res) => {
          if (times == 1) {
            this.place1 = res.data;
            if (this.form.order_pool_id == "" || c == 1) {
              this.oneplace = res.data[0].province_id;
            }

            this.getNewProvince(this.oneplace, 2, 1);
          } else if (times == 2) {
            this.place2 = res.data;
            if (this.form.order_pool_id == "" || c == 1) {
              this.twoplace = res.data && res.data[0].city_id;
            }

            // this.form.city_id = res.data[0].city_id;
            this.getNewProvince(this.twoplace, 3, 1);
          } else {
            this.place3 = res.data;
            if (this.form.order_pool_id == "" || c == 1) {
              let arr = [];
              this.place3.map((item) => {
                arr.push(item.county_id);
              });
              if (arr.indexOf(this.threeplace) == -1) {
                this.threeplace = res.data[0].county_id;
              }
            }

            // this.form.county_id = res.data[0].county_id;
          }
          this.getChanelList();
          this.wuyes(this.twoplace);

          this.getproblems(this.twoplace);
          this.House = "";
          this.getxiaoqu();
        });
    },
    choseplace(id, times, hand) {
      if (times == 1) {
        // this.place1 = res.data;
        this.getNewProvince(this.oneplace, 2, 1);
      } else if (times == 2) {
        // this.place2 = res.data;
        this.getNewProvince(this.twoplace, 3, 1);
      }
    },
    //====================================================================>

    //个性化问题单选
    perRadioChange() {
      this.$forceUpdate();
    },
    inputChange(e) {
      this.$forceUpdate(e);
    },
    checkForm() {
      //console.log(this.form, "打的标签++++");
      let form = Object.values(this.form).filter(Boolean).flat();
      form = form.map((v) => ({
        contact_label: v.contacts_id,
        contact_name: v.title,
        classification: v.content,
      }));
    },
    checkRadio() {
      //console.log(this.form, "单选值改变之后的数据改变++++");
      this.$nextTick(() => {
        this.form = this.form;
      });
    },
    getContactLabel() {
      this.util.get(this.HOST + "/Mast/contactLabel", {}).then((res) => {
        if (res.code == 200) {
          this.arr = res.data;
          this.arr.forEach((v) => {
            if (v.data[0].option == 1) {
              this.$set(this.form, v.title, "");
            } else {
              this.$set(this.form, v.title, []);
            }
          });
        }
      });
    },
    clearOverlays(overlays) {
      // 除地图上的marker
      var overlay;
      while ((overlay = overlays.pop())) {
        overlay.setMap(null);
      }
    },
    radioChange(e) {
      //console.log(e, "这是单选项+++++");
      this.shop(this.location.lat, this.location.lng, true);
      this.onequestion = e;
      this.choseques(e, "1", "hand");
    },
    handleChange() {},
    handleChange1() {},
    getproblems(shengid) {
      if (shengid) {
        this.util
          .get(this.HOST + "/Common/programme", {
            city_id: shengid,
          })
          .then((res) => {
            if (res.code == 200) {
              this.problems = res.data;
              this.problems.forEach((item, index) => {
                item.personality.forEach((i, k) => {
                  //console.log(i);
                  i.childern.forEach((u, n) => {
                    u["choooooose"] = "";
                  });
                });
              });
              //console.log(this.perProblem);
            } else {
              //   this.$message({
              //     showClose: true,
              //     message: res.msg,
              //     type: "error",
              //     duration: 1200,
              //     offset: 90,
              //   });
            }
          });
      }
    },

    defaultHouse() {
      //console.log(this.NewItem3[0], "this.NewItem3[0]");
      this.House = this.NewItem3[0].title;
      this.threeplace = this.NewItem3[0].district;
      this.place3.map((item1) => {
        if (this.NewItem3[0].district == item1.name) {
          this.details.county_id = item1.id;
        }
      });
      this.street = this.NewItem3[0].address;
      this.location.lat = this.NewItem3[0].location.lat.toFixed(5);
      this.location.lng = this.NewItem3[0].location.lng.toFixed(5);
      this.xiaoqukuang = false;
      this.noxiaoqu = false;
      this.isheight = "0px";
    },

    getquestion(parent_id, times, hand, hand2) {
      // console.log("这是哪里的", this.oneplace, this.twoplace);
      this.util
        .get(this.HOST + "/Common/goodsCategory", {
          parent_id: parent_id,
          city_id: this.twoplace,
          //uu: this.oneplace,
        })
        .then((res) => {
          if (res.code == 200) {
            if (times == "1") {
              if (!hand) {
                this.onequestion = res.data[0].title;
                this.question1 = res.data;
                this.details.pro_id = res.data[0].id;
                this.getquestion(res.data[0].id, "2");
                // this.getwhere();
                this.getChanelList();
              } else {
                this.getquestion(this.details.pro_id, "2", "hand");
              }
            } else if (times == "2") {
              // //console.log(hand)
              if (!hand) {
                this.twoquestion = res.data[0].title;
                this.question2 = res.data;
                this.details.pro_id1 = res.data[0].id;
                this.getquestion(res.data[0].id, "3");
              } else {
                if (!hand2) {
                  this.twoquestion = res.data[0].title;
                  this.details.pro_id1 = res.data[0].id;
                  this.question2 = res.data;
                } else {
                  this.question3 = res.data;
                }
                this.getquestion(this.details.pro_id1, "3", "hand");
              }
            } else {
              this.question3 = res.data;
              if (hand) {
                this.threequestion = res.data[0].title;
                this.details.pro_id2 = res.data[0].id;
              } else {
                this.details.pro_id2 = res.data[0].id;
                this.threequestion = res.data[0].title;
                this.loading = false;
              }
            }
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    choseques(parent_id, times, hand, hand2) {
      //console.log(parent_id, "这是选的主要问题+++");
      if (times == "1") {
        this.radio = parent_id;
        this.details.pro_id = parent_id;
        this.getquestion(parent_id, times, hand);
      } else if (times == "2") {
        this.details.pro_id1 = parent_id;
        if (hand2) {
          this.getquestion(parent_id, times, hand, hand2);
        } else {
          this.getquestion(parent_id, times, hand);
        }
      } else {
        this.details.pro_id2 = parent_id;
      }
    },
    moren() {
      this.onewhere = 35;
      this.sources2 = [];
      this.twowhere = "";
    },
    phone2() {
      if (this.phone != "") {
        this.util
          .get(this.HOST + "/Order/phone", {
            phone: this.phone,
          })
          .then((res) => {
            if (res.code == 200) {
              this.isVip = res.data.isVip;
              if (res.data.count != 0) {
                this.newOrOldUser = 2;
                this.$notify({
                  title: "警告",
                  message: "此电话号码已存在",
                  offset: 100,
                  duration: 0,
                  type: "warning",
                });
              } else {
                this.newOrOldUser = 1;
              }
            }
          });
      }
    },
    notify() {
      this.notifyInstance = this.$notify({
        title: "警告",
        message: this.phone + " 号码已存在!",
        offset: 100,
        duration: 20000,
        type: "warning",
      });
    },

    preiswu() {
      if (this.NewItems.length == 0 && this.nowuye) {
        this.$message({
          showClose: true,
          type: "error",
          message: "物业不存在,请重新选择!",
          duration: 1500,
          offset: 90,
        });
        setTimeout(() => {
          this.wuyeVal = "";
          this.wuyeid = "";
          return false;
        }, 1500);
      }
    },
    shop(aa) {
      // this.NewItems2 = []
      // this.diankuang = true;
      // this.nodiankuang = true;
      this.isheight = this.util.gaodu() + "px";
      this.util
        .get(this.HOST + "/Common/user", {
          city_id: this.twoplace,
          goods_category_id: this.radio,
          suibian: 0,
          lat: this.location.lat,
          lng: this.location.lng,
          storeManagerName: this.shopper,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
            if (!aa) {
              this.loading = false;
            }
          } else {
            if (!aa) {
              this.loading = false;
            }
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 推客人员
    gettui() {
      this.util
        .get(this.HOST + "/Common/TuKe", {
          // city_id: this.diandianid,
          // city_id: 239,
        })
        .then((res) => {
          if (res.code == 200) {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].username1 == null) {
                res.data[i].username1 = "暂无";
              }
              if (res.data[i].username2 == null) {
                res.data[i].username2 = "暂无";
              }
              if (res.data[i].title == "个人推客") {
                res.data[i].title = "VIP推客";
                res.data[i].username2 = "";
                res.data[i].username1 = "";
              } else if (res.data[i].title == "集团推客") {
                res.data[i].title = "推单侠";
              }
              if (res.data[i].username / 1 != NaN) {
                res.data[i].username = res.data[i].username;
              } else if (res.data[i].username / 1 == NaN) {
                res.data[i].username = "暂无姓名";
              }
              if (res.data[i].title == "VIP推客") {
                this.tuirens.push({
                  personal_id: res.data[i].personal_id,
                  username: res.data[i].username + `_` + res.data[i].city,
                  city: res.data[i].city,
                  tuiShow:
                    res.data[i].username +
                    "_" +
                    res.data[i].mobile +
                    "_" +
                    res.data[i].title,
                });
              } else {
                this.tuirens.push({
                  personal_id: res.data[i].personal_id,
                  username: res.data[i].username + `_` + res.data[i].city,
                  city: res.data[i].city,
                  tuiShow:
                    res.data[i].username +
                    "_" +
                    res.data[i].mobile +
                    "_" +
                    res.data[i].title +
                    "_" +
                    res.data[i].username2 +
                    "_" +
                    res.data[i].username1,
                });
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },

    //获取渠道
    getChanelList() {
      if (this.twoplace != "") {
        this.util
          .get(this.HOST + "/Common/chanel", {
            city_id: this.twoplace,
            //uu: this.oneplace
          })
          .then((res) => {
            if (res.code == 200) {
              this.sources = res.data;
              this.sources.map((item) => {
                if (item.id == this.onewhere) {
                  console.log(
                    "=======================================================================",
                    this.twowhere
                  );
                  this.sources2 = item.data;
                }
              });
            }
          });
      }
    },
    chosewhere(idx) {
      this.sources2 = [];
      this.twowhere = "";
      console.log(this.sources);
      for (let i = 0; i < this.sources.length; i++) {
        if (this.sources[i].id == idx) {
          this.sources2 = this.sources[i].data;
          console.log(this.sources2);
        }
      }
    },
    chosewhere1(enent) {
      this.twowhere = enent;
    },
    // 店长
    getdian(event) {
      this.diankuang = true;
      this.nodiankuang = true;
      this.isheight = this.util.gaodu() + "px";
    },
    chosedian(row) {
      this.shopperid = row.user_id;
      this.shopper = row.usernamestorename;
      this.diankuang = false;
      this.nodiankuang = false;
      this.isheight = "0px";
    },
    // 小区
    getxiaoqu() {
      if (this.House == "") {
        this.xiaoqukuang = false;
        this.isheight = "0px";
        this.noxiaoqu = false;
        return false;
      }
      let city = "";
      this.place2.map((item) => {
        if (item.city_id == this.twoplace) {
          city = item.city;
        }
      });

      this.Houseitems = [];
      this.xiaoqukuang = true;
      this.noxiaoqu = true;
      this.isheight = this.util.gaodu() + "px";
      this.$jsonp(this.HOST2, {
        key: "QWCBZ-MG26P-QNNDD-VLXUT-VJSPT-NVBLG",
        region: city,
        keyword: city + this.House,
        output: "jsonp",
      }).then((res) => {
        console.log(res, "+++++++++++++++++");
        if (res.status == 0) {
          if (res.count == 0) {
            this.$message({
              showClose: true,
              type: "error",
              message: "无相关地址,请准确输入!",
              duration: 2000,
              offset: 90,
            });
            this.$loading().close();
          } else {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].district) {
                res.data[i].title2 =
                  res.data[i].title + "(" + res.data[i].district + ")";
              } else {
                res.data[i].title2 = res.data[i].title;
              }
            }
            this.Houseitems = res.data;
          }
        }
      });
    },
    chosexiaoqu(item, idx) {
      // this.oneplace = item.province
      // this.place1.map(item1 => {
      //   if(item.province == item1.fullname){
      //     this.details.province_id = item1.id
      //   }
      // })
      // this.twoplace = item.city;
      console.log(item, "=====================>");
      this.place2.map((item2) => {
        if (item.city == item2.fullname) {
          this.details.city_id = item2.id;
        }
      });
      this.place3.map((i) => {
        if (item.district == i.county) {
          this.threeplace = i.county_id;
        }
      });

      this.place3.map((item3) => {
        if (item.district == item3.fullname) {
          this.details.county_id = item3.id;
        }
      });
      this.street = item.address;
      this.location.lat = item.location.lat;
      this.location.lng = item.location.lng;
      this.longitude = item.location.lng.toFixed(5);
      this.latitude = item.location.lat.toFixed(5);
      this.shop(this.location.lat, this.location.lng, true);
      // this.initMap(this.latitude, this.longitude);
      this.House = this.NewItem3[idx].title;
      this.xiaoqukuang = false;
      this.noxiaoqu = false;
      this.isheight = "0px";
    },
    closexiaoqu() {
      this.xiaoqukuang = false;
      this.isheight = "0px";
      this.noxiaoqu = false;
    },
    // 物业
    getwuye(event) {
      this.wuyekuang = true;
      this.nowuye = true;
      this.isheight = this.util.gaodu() + "px";
    },
    // 物业
    getwuye(event) {
      this.wuyekuang = true;
      this.nowuye = true;
      this.isheight = this.util.gaodu() + "px";
    },
    outwuye() {
      this.wuyekuang = false;
      this.nowuye = false;
      this.diankuang = false;
      this.nodiankuang = false;
      this.xiaoqukuang = false;
      this.noxiaoqu = false;
      this.isheight = "0px";
      this.$parent.cloosePoolToAsign();
    },
    chosewuye(idx, event) {
      this.wuyeid = this.NewItems[idx].group_id;
      this.wuyeVal = this.NewItems[idx].username;
      this.wuyekuang = false;
      this.nowuye = false;
      this.isheight = "0px";
    },
    closewuye() {
      this.wuyekuang = false;
      this.isheight = "0px";
      this.nowuye = false;
    },
    chosezanwu() {
      this.nowuye = false;
      this.nodiankuang = false;
    },
    getLocation() {
      this.$jsonp(this.HOST3, {
        location: this.latitude + "," + this.longitude,
        key: "QWCBZ-MG26P-QNNDD-VLXUT-VJSPT-NVBLG",
        output: "jsonp",
      }).then((res) => {
        if (res.status == 0) {
          this.House = res.result.address_reference.landmark_l2.title;
          // this.xiaoqu = res.result.address_reference.landmark_l2.title,
          this.street =
            res.result.address_component.province +
            res.result.address_component.city +
            res.result.address_component.district +
            res.result.address_component.street_number;
          this.location.lat = res.result.location.lat;
          this.location.lng = res.result.location.lng;
        }
      });
    },
    // 图片上传
    uploadFile(file) {
      this.formDate.append(file, file.file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.tudiag = true;
    },
    upsuccess(response, file, fileList) {
      // //console.log(response);
      this.imgs.push(response.data.file.path);
    },
    afterRemove(file, fileList) {
      for (var i = 0; i < this.imgs.length; i++) {
        if (this.imgs[i] == file.response.data.file.path) {
          this.imgs.splice(i);
        }
      }
    },
    addsub(no, x) {
      this.pool = x ? x : false;
      let text = "指派中...";
      if (this.stytem == 1) {
        this.shopper = "";
        this.shopperid = "";
      }
      if (no == "no") {
        text = "保存中...";
        if (this.wuxiaozhi == "") {
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择无效订单原因!",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
      } else {
        if (this.House == "") {
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择小区详细地址",
            duration: 2000,
            offset: 90,
          });
          return false;
        }

        if (this.shopper == "" && this.stytem == 0 && !x) {
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择指派店长!",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
      }
      if (this.util.datatime(this.xiadantime) == "NaN-NaN-NaN NaN:NaN:NaN") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入下单时间",
          duration: 1200,
          offset: 90,
        });
        return false;
      }

      if (this.Customer == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入客户姓名!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.phone == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入客户联系电话!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.onewhere == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择订单来源!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.sources2.length > 0 && this.twowhere == "") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择二级订单来源!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.labelCheck.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择问题标签下的位置",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      if (this.labelCheck1.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择问题标签下的问题类型",
          duration: 2000,
          offset: 90,
        });
        return false;
      }

      let arr = [];
      this.labelCheck.map((item) => {
        this.problems.map((data) => {
          if (data.position) {
            data.position.map((data1) => {
              let select = 0;
              if (data.id == this.radio) {
                this.radio = data.id;
                select = 1;
              }
              if (item === data1.id) {
                if (
                  arr.findIndex((id1) => {
                    return id1.id === data.id;
                  }) > -1
                ) {
                  arr[
                    arr.findIndex((id1) => {
                      return id1.id === data.id;
                    })
                  ].position.push({
                    ...data1,
                  });
                } else {
                  arr.push({
                    select: select,
                    id: data.id,
                    title: data.title,
                    position: [{ ...data1 }],
                    problem: [],
                  });
                }
              }
            });
          }
        });
      });
      this.labelCheck1.map((item) => {
        this.problems.map((data) => {
          if (data.problem) {
            data.problem.map((data2) => {
              let select1 = 0;
              if (data.id == this.radio) {
                this.radio = data.id;
                select1 = 1;
              }
              if (item === data2.id) {
                if (
                  arr.findIndex((id2) => {
                    return id2.id === data.id;
                  }) > -1
                ) {
                  arr[
                    arr.findIndex((id2) => {
                      return id2.id === data.id;
                    })
                  ].problem.push({
                    ...data2,
                  });
                } else {
                  arr.push({
                    select: select1,
                    id: data.id,
                    title: data.title,
                    position: [],
                    problem: [{ ...data2 }],
                  });
                }
              }
            });
          }
        });
      });
      let mark = 0;
      let flag = arr.some((item) => {
        return item.select == 1;
      });
      if (!flag) {
        mark = 3;
        this.$message({
          showClose: true,
          type: "error",
          message: "请设置一个主要问题且主要问题与问题标签相对应!",
          duration: 2000,
          offset: 90,
        });
        return false;
      }
      arr.map((item) => {
        if (item.position.length > 0 && item.problem.length == 0) {
          mark = 1;
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择问题标签下的问题类型标签!",
            duration: 2000,
            offset: 90,
          });
          return false;
        }
        if (item.problem.length > 0 && item.position.length == 0) {
          mark = 2;
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择问题标签下的位置类型标签!",
            duration: 2000,
            offset: 90,
          });
          return false;
        }
      });
      if (mark != 1 && mark != 2 && mark != 3) {
        this.problemArr = arr;
      } else {
        return false;
      }
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: text,
      });
      if (this.location.lat != "" && this.location.lng != "") {
        this.Assign(this.location.lat, this.location.lng, no);
      }
    },
    wuxiao2() {
      this.wuxiao = 1;
    },
    // 获取物业
    wuyes(shengid) {
      // this.NewItems = [];
      //console.log("到没到获取物业接口里面来+++++");
      this.wuyekuang = true;
      this.util
        .get(this.HOST + "/Common/residential", {
          city_id: shengid,
          //uu: shengid,
          type: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.items = res.data;
            //console.log(this.items, "this.items++++");
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: "获取物业失败!",
              duration: 2000,
              offset: 90,
            });
          }
        });
    },
    // 提交
    Assign(lat, lng, no) {
      let shengid = "";
      let shiid = "";
      let quid = "";
      let state = 1;
      let timem = this.util.datatime(this.xiadantime);
      let sms = 0;
      let sms1 = 0;
      let tuiren = 0;
      if (this.tuiren != "") {
        tuiren = this.tuiren;
      }
      let message = "订单指派成功!";
      if (no == "no") {
        state = 10;
        message = "订单保存成功!";
      }
      let form = Object.values(this.form).filter(Boolean).flat();
      form = form.map((v) => ({
        contact_label: v.contacts_id,
        contact_name: v.title,
        classification: v.content,
      }));
      for (let i = 0; i < this.place1.length; i++) {
        if (this.place1[i].id == this.details.province_id) {
          shengid = this.place1[i].old_id;
        }
      }
      for (let i = 0; i < this.place2.length; i++) {
        if (this.place2[i].id == this.details.city_id) {
          shiid = this.place2[i].old_id;
        }
      }
      for (let i = 0; i < this.place3.length; i++) {
        if (this.place3[i].id == this.details.county_id) {
          quid = this.place3[i].old_id;
        }
      }
      for (let i = 0; i < this.checkList.length; i++) {
        if (this.checkList[i] != "店长") {
          sms = 1;
        }
        if (this.checkList[i] != "客户") {
          sms1 = 1;
        }
      }
      if (typeof this.onewhere == "string") {
        for (let i = 0; i < this.sources.length; i++) {
          if (this.sources[i].title == this.onewhere) {
            this.onewhere = this.sources[i].id;
          }
        }
      }
      if (this.wuyeVal) {
        for (let i = 0; i < this.items.length; i++) {
          if (this.wuyeVal == this.items[i].username) {
            this.wuyeid = this.items[i].group_id;
          }
        }
      } else {
        this.wuyeid = "";
      }
      let a = this.building ? this.building + "栋" : "";
      let b = this.unit ? this.unit + "单元" : "";
      let c = this.room ? this.room + "号" : "";
      let address = a + b + c;
      //  return false

      // return;
      let personality = [];
      this.problems.forEach((item) => {
        if (item.personality.length > 0) {
          personality.push(item.personality);
        }
      });
      //console.log("+++++++++++++++++++++++", this.poolOrderInfo.order_pool_id);
      this.util
        .post(this.HOST + "/Order/add", {
          //   isOrderPool: this.pool ? 1 : 0, //1：转至商机池2：不移订单池
          isOrderPool: this.isOrderPool, //1：转至商机池2：不移订单池
          personality: JSON.stringify(personality),
          hardbound: this.hardbound,
          pro_id: 0,
          pro_id1: this.details.pro_id1,
          pro_id2: this.details.pro_id2,
          province: this.oneplace,
          city: this.twoplace,
          county: this.threeplace,
          addres: this.House + address,
          user_id: this.shopperid,
          contacts: this.Customer,
          telephone: this.phone,
          remarks: this.textarea,
          logo: JSON.stringify(this.imgs)
            .replace(/\[|]/g, "")
            .replace(/\"/g, ""),
          lat: lat,
          lng: lng,
          residential_id: this.wuyeid,
          channel_id: this.onewhere,
          channel_details: this.twowhere,
          management: this.Recommend,
          residential_quarters: this.House,
          state: state,
          created_time: timem,
          sms1: sms1,
          sms: sms,
          reason: this.wuxiaozhi[0],
          notereason: this.wuxiaozhi[1],
          wechat: this.wechat,
          stytem: this.stytem,
          personal_id: tuiren,
          problemArr: JSON.stringify(this.problemArr),
          street: this.street,
          building: this.building,
          unit: this.unit,
          room: this.room,
          contactLabel: JSON.stringify(form),

          dispatchType: this.dispatchType, //派单类型
          visiting_time: this.visiting_time, //期望上门时间
          orderFee: this.orderFee, //是否免订单费
          constructionTime: this.constructionTime, //期望施工处理时间
          specifiedTime: this.specifiedTime, //指定时间
          sex: this.sex, //性别
          isVip: this.isVip, //是否会员
          newOrOldUser: this.newOrOldUser, //新老用户
          isQuote: this.isQuote, //是否有过报价
        })
        .then((res) => {
          if (res.code == 200) {
            // ===========================================================================================>
            // this.isOrderPool = ""; //1：转至商机池2：不移订单池
            // this.problems = [];
            // this.hardbound = "";

            // this.details.pro_id1 = "";
            // this.details.pro_id2 = "";
            // this.oneplace = "";
            // this.twoplace = "";
            // this.threeplace = "";
            // this.House = "";
            // this.building = "";
            // this.unit = "";
            // this.room = "";
            // this.shopperid = "";
            // this.Customer = "";
            // this.phone = "";
            // this.textarea = "";
            // this.imgs = [];

            // this.wuyeid = "";
            // this.onewhere = "";
            // this.twowhere = "";
            // this.Recommend = "";
            // this.House = "";
            // this.xiadantime = "";

            // this.wuxiaozhi = [];
            // this.wuxiaozhi = [];
            // this.wechat = "";
            // this.stytem = "";
            // this.tuiren = "";
            // this.problemArr = [];
            // this.street = "";

            // this.form = {};

            // this.dispatchType = ""; //派单类型
            // this.visiting_time = ""; //期望上门时间
            // this.orderFee = ""; //是否免订单费
            // this.constructionTime = ""; //期望施工处理时间
            // this.specifiedTime = ""; //指定时间
            // this.sex = ""; //性别
            // this.isVip = ""; //是否会员
            // this.newOrOldUser = ""; //新老用户
            // this.isQuote = ""; //是否有过报价
            // ===========================================================================================>
            this.$loading().close();
            this.dialogVisible = false;
            this.$parent.cloosePoolToAsign();
            // if(this.notifyInstance){
            //   this.notifyInstance.close()
            // }

            this.$message({
              showClose: true,
              message: "指派成功",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("setMessage", false);
              this.$store.commit("settask", 1);
              this.$emit("getDataAgain", this.currentPage3);
              this.$emit("update:currentPage3", this.currentPage3);
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              type: "error",
              message: res.msg,
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
  // 物业
  computed: {
    poolToAsign() {
      return this.$store.state.poolToAsign;
    },
    // 店长
    NewItems2() {
      var that = this;
      var NewItems2 = [];
      this.shoppers.map(function (item) {
        if (item.usernamestorename.search(that.shopper) != -1) {
          NewItems2.push(item);
        }
      });
      return NewItems2;
    },
    // 小区
    NewItem3() {
      var that = this;
      var NewItem3 = [];
      this.Houseitems.map(function (item) {
        // if (item.title2.search(that.House) != -1) {
        NewItem3.push(item);
        // }
      });
      return NewItem3;
    },
    getStoreItem() {
      return this.$store.state.dialogTableVisible;
    },
  },
};
</script>
<style>
.BBg {
  width: 100%;
  /* position: fixed; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
}

.addorder {
  padding: 0 0 0 3%;
}

#container {
  /*地图(容器)显示大小*/
  width: 200px;
  height: 200px;
}

.title {
  /* background: #545c64; */
  /* color: white; */
  background: white;
  color: #666;
  width: 120px;
  cursor: pointer;
  font-size: 20px;
}

.quyu {
  width: 130px;
}

.ltitle {
  width: 65px;
}

/* 店长框 */
.diankuang {
  top: 30px;
  left: 76px;
  width: 204px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 473px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
}

.diankuang li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.diankuang li:hover {
  background-color: #f5f7fa;
}

.zanwudian {
  padding: 10px 0;
  margin-left: 20px;
  text-align: center;
  color: #999;
  font-size: 14px;
  top: 20px;
  left: 70px;
  width: 204px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #e4e7ed;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}

/* 小区 */
.xiaoqukuang {
  top: 37px;
  left: 76px;
  width: 202px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
}

.xiaoqukuang li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.xiaoqukuang li:hover {
  background-color: #f5f7fa;
}

/* 物业 */
.wuyekuang {
  top: 37px;
  left: 76px;
  width: 202px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
}

.wuyekuang li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.wuyekuang li:hover {
  background-color: #f5f7fa;
}

li {
  list-style-type: none;
}

.zanwu {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 14px;
  top: 50px;
  left: 76px;
  width: 202px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #e4e7ed;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}

.el-textarea__inner {
  width: 400px;
  height: 96px;
}

.el-upload-list__item-preview {
  display: none !important;
}

.el-input__inner {
  height: 30px !important;
  line-height: 30px !important;
}

.el-input__icon {
  line-height: 25px !important;
}

.el-date-editor .el-range-separator {
  line-height: 22px !important;
}

.el-button {
  padding: 8px 20px !important;
}

.el-table td,
.el-table th {
  padding: 5px 0 !important;
}

.el-form-item--feedback {
  margin: 12px auto !important;
}

.picker-slot-wrapper {
  transform: translate(0px, 20px) translateZ(0px) !important;
}

.el-textarea {
  width: 400px !important;
}

.inputBottom {
  margin-bottom: 15px;
  height: 32px;
}

.titleBottom {
  margin-bottom: 8px;
}

.liTitle {
  width: 70px;
}

.liTitle2 {
  width: 90px;
}

.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}

.el-form-item {
  margin-bottom: 1px !important;
}
</style>
